.dashboard-tile{
    /* min-height: 300px; */
    margin: 17px;
    max-width: 480px;
    min-width: 250px;
    padding: 0 20px 20px 20px;
    box-shadow: 1px 1px 4px 1px #c7c7c7;
    background-color: white;
    border-radius: 20px;
    overflow: auto;
    height: 500px;
}

.dashboard-page{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}