.App {
  /* text-align: center; */
  /* background-color: #fff0de; */
}

body{
  /* background-color: rgb(214, 200, 221); */
  background-color: #F2EEF3;
  color: #405d43;
  font-size: 14px;
  font-family: 'Karla', sans-serif;
}
#root{
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h2{
  font-size: 24px;
}